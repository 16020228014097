header {
    padding: 25px 0 0;
}

.topBannerContainer {
    .bannerBlock {
        background: #211810 url("../assets/images/shiva.jpeg") no-repeat center top;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        position: relative;
        padding-bottom: 60px;

        .logoImg {
            width: 110px;
            margin-top: 11px;
            margin-right: 11px;
        }

        .logo {
            span {
                color: #00a779;
                // color: #fff;
                font-size: 42px;
                display: inline-block;
                letter-spacing: 6px;
            }

            .text-top {
                padding: 0;
                text-align: left;
                padding-bottom: 4px;
                border-bottom: 1px solid #fff;

            }

            .text1{
                min-width: 200px;
                text-align: left;
                .txtstudio{
                    font-size: 22px!important;
                    letter-spacing: 0;
                }
            }
            .text3 {
                min-width: 199px;
                text-align: left;
            }

            .text2,
            .text4 {
                font-size: 30px;
                border-top: 1px solid #fff;
                padding-left: 7px;
                transform: rotate(-90deg);
            }

            .text2 {
                margin: -17px 0px;
                padding-left: 14px;
            }

            .text4 {
                padding-right: 11px;
                margin: -4px;
                letter-spacing: 0px;
            }
        }

    }

    .overlay-bg {
        position: absolute;
        background: #000;
        opacity: 0.75;
        width: 100%;
        height: 100% !important;
        left: 0;
        right: 0;
        top: 0;
        z-index: 0;
    }

    .logo {
        text-align: center;
        margin: 0 auto;
        display: table;
    }

    #cssmenu {
        border: none;
        border: 0;
        margin: 30px auto 0;
        padding: 0;
        font-size: 14px;
        width: auto;
        display: table;
        position: relative;
        z-index: 99;

        ul {
            height: 41px;
            list-style: none;
            margin: 0;
            padding: 0;
            border-top: 1px solid #3e3b39;
            border-bottom: 1px solid #3e3b39;

            li {
                float: left;
                padding: 0;

                a,
                a:visited {
                    color: #fff;
                    display: block;
                    font-weight: 400;
                    line-height: 41px;
                    margin: 0;
                    padding: 0 12px;
                    text-align: center;
                    text-decoration: none;
                    font-size: 14px;
                }

                a.active {
                    color: #e96546;
                    // font-weight: bold;
                    text-decoration: underline;

                }

                ul {
                    display: none;
                    height: auto;
                    padding: 0;
                    margin: -2px 0 0;
                    border: 0;
                    position: absolute;
                    min-width: 170px;
                    z-index: 200;

                    // -webkit-box-shadow: 3px 3px 0 0 rgb(0 0 0 / 15%);
                    // box-shadow: 3px 3px 0 0 rgb(0 0 0 / 15%);
                    li {
                        display: block;
                        float: none;
                        margin: 0;
                        padding: 0;
                        background: #e96546;
                        border-left: 2px solid #e96546;

                        a {
                            display: block;
                            height: 39px;
                            font-size: 12px;
                            font-style: normal;
                            margin: 0;
                            padding: 0 15px;
                            text-align: left;
                            color: #fff !important;
                            font-family: Sanchez;
                        }
                    }
                }
            }
        }

    }

    .welcome {
        position: relative;
        z-index: 1;

        h2 {
            font-size: 32px;
            text-align: center;
            color: #FFF;
            margin: 0 auto;
            width: 85%;
            line-height: 40px;
        }
    }

    .slider-content {
        position: relative;

        #carousel {
            width: 100%;
            min-height: 330px;
            position: relative;
            overflow: hidden;
            padding-top: 50px;
            padding-bottom: 50px;
            display: flex;

            .cimage,
            button,
            .owl-buttons div {
                -webkit-transition: all .1s ease-in-out;
                -moz-transition: all .1s ease-in-out;
                -o-transition: all .1s ease-in-out;
                transition: all .1s ease-in-out;
            }

            &>.cimage {
                flex: 1 1;
                flex-direction: row;
                flex-wrap: wrap;
                margin: 21px;
                border-radius: 14px;
                background: #2b2b2b;
                box-shadow: 1px 3px 20px #fff;
                overflow: hidden;
                max-height: 16em;
                max-width: 13em;
            }

            img {
                cursor: pointer;
                width: 100%;
                height: 100%;
                // margin-left: 15px;
            }
        }

        .scroll-btns {
            display: block;
            width: 100%;
            position: absolute;
            top: 45%;

            #s-prev,
            #s-prev1 {
                color: #fff;
                width: 50px;
                height: 50px;
                display: table;
                float: left;
                position: relative;
                left: -35px;
                border-radius: 50%;
                border: 2px solid #fff;
                font-size: 0;
                z-index: 999;
            }

            #s-next,
            #s-next1 {
                color: #fff;
                width: 50px;
                height: 50px;
                display: table;
                float: right;
                position: relative;
                right: -4px;
                border-radius: 50%;
                border: 2px solid #fff;
                font-size: 0;
                z-index: 999;
            }
        }

    }
}

.sticky-top {
    position: fixed;
    top: 0;
    z-index: 1020;
    width: 100%;
    display: none;

    img.toplogo {
        max-width: 55px;
    }

    .logoImg {
        width: 50px !important;
        margin-top: 5px !important;
        margin-right: 11px;
    }

    .logoTextBlock {
        margin-top: -14px !important;
    }

    .logo span {
        font-size: 21px !important;
    }

    .text1 {
        font-size: 22px;
        margin-top: 1em;
        color: #01a676;
    }
}

.displayNav {
    display: inline-block !important;
}



@media screen and (min-width: 320px)and (max-width: 820px) {
    .topBannerContainer {
        .bannerBlock {
            padding-bottom: 0;
            max-height: 50em;

            .logoTextBlock {
                .text3 {
                  min-width: 200px;
                }
            }
            .sticky-top{
                .text1 {
                    color: #00a779;
                    min-width: 160px;
                    .txtstudio{
                        display: none;
                    }
                  
                }
            }
            .logo {
                .text4 {
                    margin: -6px !important;
                }
            }

            .navbar-toggler {
                margin-top: 7px;
            }

        }

        .slider-content {
            #carousel {
                overflow-x: auto;
                padding-top: 50px;
    padding-bottom: 60px;
                &>.cimage {
                    min-width: 13em;
                }
            }
        }
        #cssmenu ul li {
            a,a:visited{
                padding: 0 6px;
                font-size:12px
            }
        }
    }

    #navbarText {
        width: 100%
    }

    .shopWithUsContainer {
        .productImageBlock .productBlock {
            width: 100%;
            margin: 0;

            .priceTag {
                padding: 16px;
                background: #fff;

                &>p {
                    font-size: 15px;
                    margin-bottom: 10px;
                }
            }
        }
    }
}