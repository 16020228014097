.body {
    position: relative;
    width: 100%;
    overflow: hidden;
    font-size: 16px;
    // font-family: "Open sans", Arial, Helvetica, sans-serif;
    font-family: Verdana, Tahoma, sans-serif !important;

}

.v-hidden {
    visibility: hidden !important
}

.head-title {
    margin-bottom: 30px;
    color: #e96546;
    font-family: cursive;
    text-align: center;
    text-decoration: overline;

    &~.subHeading-title {
        margin-top: -20px;
        line-height: 21px;
        margin-bottom: 50px;
        text-align: center;
    }
}

.homeContainer {
    margin: 0 auto;

    &>.container {
        margin: 11px auto;
        display: inline-block;
        border-bottom: 1px solid #cdcdcd;
        width: 100%;
        padding: 50px 20px;
        box-shadow: 0px 1px 10px #ccc;
    }
}

.no-slider {

    .slider-content,
    .welcome {
        display: none;
    }

    .bannerBlock {
        margin-bottom: 50px;
    }
}

.Profile {
    .WhoWeAre {
        .redirectBtn {
            display: none
        }
    }

    .brandlist {
        width: 130px;
        float: left;
        margin: 10px 20px;
        min-height: 12em;
        text-align: center;
        max-height: 1em;

        .brandIcon {
            min-height: 140px;

            img {
                max-width: 100%;
                max-height: 120px;
            }
        }

        p {
            font-size: 14px;
            color: #c9320f;
        }

    }
}

.deliveryForm {
    margin-top: 50px;
}
.txtgreen{
    color:green!important
}
.txtred{
    color:red
}
.txtgrey{
    color:grey!important
}

@keyframes slidy {
    0% {
        left: 0%;
    }

    20% {
        left: 0%;
    }

    25% {
        left: -100%;
    }

    45% {
        left: -100%;
    }

    50% {
        left: -200%;
    }

    70% {
        left: -200%;
    }

    75% {
        left: -300%;
    }

    95% {
        left: -300%;
    }

    100% {
        left: -400%;
    }
}


.imageslider {
    overflow: hidden;

    figure {
        position: relative;
        width: 500%;
        margin: 0;
        left: 0;
        text-align: left;
        font-size: 0;
        animation: 30s slidy infinite;

        img {
            float: left;
            max-width: 100%;
            max-height: 294px;
        }
    }

}

.shopWithUsContainer{
    .productImageBlock {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        .productBlock {
            width: 23%;
            border-radius: 8px;
            overflow: hidden;
            margin-right: 20px;
            height: 320px;
            margin-bottom: 20px;
            background: #2b2b2b;
            border: 1px solid #cdcdcd;
            position: relative;
            img{max-width: 100%;}
            .priceTag {
                position: absolute;
                width: 100%;
                bottom: 0;
                left: 0;
                background: #fff;
                padding: 10px;
                &>p {
                    margin: 0;
                    background: #fff;
                    font-size: 11px;
                    padding: 0 10px;
                    line-height: 21px;
                    vertical-align: bottom;
                }
            }
        }
    }
}
.productImageBlock{
    .productImage{
        width: 320px;
    overflow: hidden;
    height: 30em;
    border-radius: 10px;
    background: #2b2b2b;
    box-shadow: 1px 1px 10px #dedede;
    text-align: center;
    padding:0;
    }
    .paymentModeBlock{
        img.gpayIcon {
            width: 63px;
        }
        .refNo {
            font-size: 20px;
            font-weight: bold;
            text-decoration: underline;
        }
    }
    .productDetailsBlock {
        padding-left: 119px;
        color: #565959;
        .productTitle {
            font-size: 24px;
            word-break: break-word;
            line-height: 32px!important;
        }
        &>div{
            font-size: 14px;
        }
        .txtStrike span {
            text-decoration: line-through;
        }
        .txtPrice span {
            color: #B12704!important;
            font-size: 18px;
            line-height: 24px;
        }
        .productLeft {
            font-size: 18px!important;
            color: #067D62!important;
            margin-top:15px;
        }
        .pdetailsection{
            &>div{
                margin-bottom: 5px;
                font-size: 14px;
                letter-spacing: 1px;
                margin-bottom:15px;
                strong{
                    font-weight: 700;
                   
                    width: 157px;
                    display: inline-block;
                    text-align: left;
                }
                span{
                    font-weight: 400;
                }
            }
        }
    }
}

.trackResultBlock{
    margin-top: 30px;
    display: inline-block;
    .orderImage{
        width: 120px;
        overflow: hidden;
        height: 120px;
        img{
            max-width: 100%;
        }
    }
    .orderDetailsBlock {
        display: inline-block;
        width: 100%;
        border-bottom: 1px solid #cdcdcd;
        margin-bottom: 30px;
    }
    .orderDetails {
        &> p {
            margin: 0;
            font-weight: 700;
            font-size: 12px;
            padding-left: 23px;
            >span{
                font-weight: 400;
            }
        }
        .txtAmount span {
            font-size: 16px;
            color: #ee8b74;
        }
    }
   
}

.footerBlock {
    background: #2b2b2b;
    color: #fff;
    padding: 15px;
    &> p {
        margin: 0;
        font-size: 12px;
        color:#8ea8c4;
        // float:right;
        // width:auto;
    }
    .cwPara{
        color:#03ab7d;
        // float:left;
    }
}
.loginContainer{
    h5{text-align:center;margin-top: 30px;margin-bottom: 30px;}
    .loginBlock {
        max-width: 320px;
        margin: 0 auto;
    }
}
.shopnowBtn {
	box-shadow: 0px 0px 0px 2px #9fb4f2;
	background:linear-gradient(to bottom, #7892c2 5%, #476e9e 100%);
	background-color:#7892c2;
	border-radius:10px;
	border:1px solid #4e6096;
	display:inline-block;
	cursor:pointer;
	color:#ffffff;
	font-family:Verdana;
	font-size:19px;
	padding:12px 37px;
	text-decoration:none;
    text-shadow:0px 1px 0px #283966;
    &:hover {
        background:linear-gradient(to bottom, #476e9e 5%, #7892c2 100%);
        background-color:#476e9e;
    }
}

.shopnowBtn:active {
	position:relative;
	top:1px;
}
.imageModal{
    background-color:#111;
    .modal-content{
        background-color:#111;
    }
    .modal-header{
        border:0;
        padding:0;
        .close{
            color: #fff;
    opacity: 9;
    font-weight: normal;
    font-size: 40px;
    margin-right: 0;
        }
    }
 
    .modal-body{
        margin:0 auto;
        img{
            max-width:100%;
        }
    }
}

@media screen and (min-width: 320px)and (max-width: 820px) {

    .Profile {
        .brandlist{
            margin: 10px 7px;
            .brandIcon {
                min-height: 130px;
            }
        }
    }
    .testiminialForm {
        margin-top: 30px;
        padding: 0;
    }
    .ourServices {
        height: 14em;
        min-height:14em!important;
        padding: 50px 0!important;
        .service-content {
            margin-bottom: 50px;
            &:first-child{
                margin-left: -16px;
            }
            .serviceIcon{
                width:65px;
                height:65px;
                .customIcon {
                    max-width: 107px;
                    max-height: 175px;
                    margin-top: -52px!important;
                }
                img {
                 
                    margin-top: -11px;
                }
                span {
                    width: 20px;
                    height: 20px;
                    padding-top: 0;
                    line-height: 20px;
                   
                    border: 1px solid #888;
                    font-size: 10px;
                   
                }
            }
            h4{font-size: 14px;
                font-weight: bold;
                color: #01a777;}
        }
    }

    .WhoWeAre .mainblock .image {
        width: 100% !important;
        border-radius: 4px;
    overflow: hidden;
    box-shadow: 4px 7px 11px #cdcdcd;
    background: #000;
    }
    .Profile{
        .WhoWeAre .mainblock .image {background:#fff}
        .brandlist{
            width: 130px;
        }
    }

    .Portfolio .imageBlock .imageInner {
        margin: 0 auto;
        max-width: 108px;
        max-height: 120px;
        margin-bottom: 10px;
        .portfolioImages{
            max-width: 165px!important;
        }

   
    }
   

    .TestimonialBlock {
        .quoteImg {
            float: none !important;
            margin-bottom: 19px;
            text-align: center;
        }

        .testimonialColumn {
            margin-bottom: 40px;
            border-bottom: 1px solid #cdcdcd;
            border-right: 0 !important;
            padding-bottom: 40px;
            text-align: center;
        }

        .quotesBlock {
            width: 100%;
            max-width: auto;
        }
    }
    .imageslider figure img {
      
        max-height: 215px;
    }
    .shopWithUsContainer {
        .productImageBlock .productBlock{
            width: 100%;
        margin: 0;
        height:400px;
        .priceTag{
            padding: 16px;
        background: #fff;
        &>p{
            font-size: 15px;
            margin-bottom: 10px;
        }
        }
        }
    }
    .productImageBlock .productDetailsBlock {
        padding-left: 15px;
        margin-top: 32px;
    }
}

@media screen and (min-width: 320px)and (max-width: 375px) {
    .Portfolio .imageBlock .imageInner {
        max-width: 108px;
        margin: 0 auto;
    }
    .brandlist{
        width: 110px;
    }
}