.recentWorkContainer {
    border-bottom: 1px solid #cdcdcd;
    min-height: 22em;
    margin-bottom: 30px;
    padding-bottom: 50px;
    padding-top: 30px;

    .imageBlock {
        border: 1px solid #cdcdcd;
        display: flex;
        overflow: hidden;
        flex-wrap: wrap;

        .col-md-2 {
            max-height: 8em;
            overflow: hidden;
        }


    }

   

    .recentWorkBlock {
        border: 1px solid #ddd;
        padding-bottom: 40px;
        padding: 2.5px;
        overflow: hidden;
        max-height: 18em;
        margin-bottom: 0;
        background: #000;

        img {
            max-width: 100%;
            height: auto;

        }



    }

}

.Portfolio {
   
    .overlay-wrap {
        padding: 20px;
        position: absolute;
        width: 100%;
        height: 100%;
        background: #e96546;
        background: rgba(233, 101, 70, 0.9);
        left: 0;
        right: 0;
        top: 0;
        opacity: 0;
        -webkit-transition: all .3s ease-in-out;
        -moz-transition: all .3s ease-in-out;
        -o-transition: all .3s ease-in-out;
        transition: all .3s ease-in-out;
        cursor: pointer;
        h5 {
            color: #fff;
            text-align: center;
            margin: 10px 0 5px;
            font-size: 16px;
        }
        p {
            color: #fff;
            text-align: center;
            font-size: 12px;
            line-height: 18px;
        }
    }
    .overlay {
        display: table;
        width: 100%;
        height: 100%;
    }
    .overlay-inner {
        display: table-cell;
        vertical-align: middle;
    }
    .imageBlock {
        display: flex;
        flex-wrap: wrap;
        border: 1px solid #cdcdcd;
        border-radius: 4px;
        box-shadow: 1px 4px 10px #000;
        padding-top: 3px;
        .imageInner{
            overflow: hidden;
        .portfolioImages {
            height: 9em;
            max-width: 236px;
            margin: 3px;
            /* background: #000; */
            position: relative;
            vertical-align: middle;
          

            img {
                max-width: 100%;
                max-height: 100%;
              
            }
            &:hover  .overlay-wrap {
                 
                opacity: 1;
                -webkit-transition: all .3s ease-in-out;
                -moz-transition: all .3s ease-in-out;
                -o-transition: all .3s ease-in-out;
                transition: all .3s ease-in-out;
            }
        }
    }
    }

}

.redirectBtn{
    float: right;
    margin-top: 7px;
    background: #e96546;
    border: 1px solid transparent;
    box-shadow: 1px 2px 10px #2b2b2b;
    a{color:#fff}
}

.WhoWeAre {
    .mainblock {
        display: inline-block;
        margin: 0 auto;
        // box-shadow: 10px 10px 10px #dedede;
        border-radius: 6px;
        margin-bottom: 30px;

        .content {
            float: left
        }

        .image {
            float: left;
            width: 300px;

            img {
                max-width: 100%
            }
        }

    }
}
.TestimonialBlock{
   
    margin-bottom: 50px;
    width: 100%;
     .quotesBlock {
        font-family: cursive;
        margin-left: 12px;
        float: left;
        max-width: 280px;
        line-height: 23px;
        strong{
            margin-top: 10px;
            float: right;
            color: #e96546;
        }
    }
    .quoteImg{
        max-width: 200px;
        max-height: 140px;
        float: left;
    }
    .testimonialColumn{
        border-right:1px solid #cdcdcd;
        display: inline-block;
        &:last-child{
            border:0;
        }
    }

}