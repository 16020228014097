.ourServices {
    padding: 70px 90px 30px;
    border-bottom: 1px solid #cdcdcd;
    min-height: 22em;
    margin-bottom: 30px;

    .service-content {
        position: relative;
        text-align: center;
        color: #444;
        float: left;
        img{
            max-width: 100%;
    max-height: 100%;
    margin-top: -21px;
        }
        .customIcon{
            max-width: 194px;
    max-height: 191px;
    margin-top: -77px;
        }

        &::before {
            display: table;
            content: " ";
        }

        &:hover {
            color: #e96546;
        }

        &:hover .serviceIcon {

            background-color: #e96546;
            border-color: #e96546;
            background-position: 50% -130px;
        }

        p {
            font-size: 13px;
            line-height: 20px;
            margin-bottom: 20px;
            text-align: center;
            padding: 0 10px;
        }
    }

    .serviceIcon {
        width: 130px;
        height: 130px;
        background: #fff;
        border-radius: 50%;
        border: 1px solid #888;
        margin: 0 auto 20px;

        i {
            font-size: 50px;
            line-height: 65px;
            color: #444;
        }

        span {
            width: 35px;
            height: 25px;
            padding-top: 5px;
            background: #fff;
            display: table;
            text-align: center;
            line-height: 25px;
            color: #444;
            border-radius: 50%;
            border: 2px solid #888;
            font-size: 19px;
            position: relative;
            font-family: Sanchez;

            &::before {
                position: absolute;
                display: block;
                content: "";
                border: 1px solid #fff;
                height: 100%;
                width: 100%;
                box-sizing: border-box;
                -moz-box-sizing: border-box;
                -webkit-box-sizing: border-box;
                border-radius: 50%;
                top: 0;
                left: 0;
                right: 0;
            }
        }
    }
}